<template>
   <b-card>
    <b-row>
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <div class="mb-3" v-for="(item, index) in getDistanceResult" :key="index">
          <table class="distances">
            <tbody>
              <tr>
                <th>Distance To</th>
                <th>{{ item[0].title }}</th>
              </tr>

              <tr v-for="(itemDetail, index) in item" :key="itemDetail.title" v-show="index !== 0">
                <th :style="{ color: 'black', backgroundColor: 'hsl('+ itemDetail.gradHSL+',100%, 50%)' }">{{ itemDetail.distance }}</th>
                <th>{{ itemDetail.title.replaceAll('*', ' ') }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-row>
          <b-col md="6" xl="6">
            <b-form-group
              label="Max Output Number"
              label-for="payment-method"
            >
              <b-form-input v-model="distMaxOut"/>
            </b-form-group>
          </b-col>

          <b-col md="6" xl="6">
            <div class="d-flex justify-content-between align-items-center my-1">
              <label for="addGradient">Add Gradient</label>
              <b-form-checkbox
                id="addGradient"
                :checked="true"
                switch
              />
            </div>
          </b-col>
          
          <b-col md="6" xl="6">
            <b-form-group
              label="Gradient From"
              label-for="payment-method"
            >
              <b-form-input v-model="gradientFrom"/>
            </b-form-group>
          </b-col>
          <b-col md="6" xl="6">
            <b-form-group
              label="Gradient To"
              label-for="payment-method"
            >
              <b-form-input v-model="gradientTo"/>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="12">
            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar-send-invoice
              variant="primary"
              class="mb-75"
              block
              @click="runAllDist()"
            >
              RUN ALL
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              v-for="item in getTargetButtons"
                :key="item.index"
              @click="dispatcher(item.index)"
            >
              {{ item.title }}
            </b-button>

          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  methods: {
    dispatcher(targetId) {
      this.$store.commit('sourceDataManagement/dispatcher', targetId)
    },

    runAllDist() {
      this.$store.commit('sourceDataManagement/runAllDist')
    },
  },
  computed: {
    distMaxOut: {
      get() {
        return this.$store.getters['sourceDataManagement/getSourceObject'].distMaxOut
      },
      set(value) {
        this.$store.commit('sourceDataManagement/setSourceObject', { key: 'distMaxOut', value })
      },
    },
    gradientFrom: {
      get() {
        return this.$store.getters['sourceDataManagement/getSourceObject'].gradientFrom
      },
      set(value) {
        this.$store.commit('sourceDataManagement/setSourceObject', { key: 'gradientFrom', value })
      },
    },
    gradientTo: {
      get() {
        return this.$store.getters['sourceDataManagement/getSourceObject'].gradientTo
      },
      set(value) {
        this.$store.commit('sourceDataManagement/setSourceObject', { key: 'gradientTo', value })
      },
    },
    getDistanceResult() {
      return this.$store.getters['sourceDataManagement/getDistanceResult']
    },
    getTargetButtons() {
      return this.$store.getters['sourceDataManagement/getTargetButtons']
    },
  },
}
</script>

<style scoped>
</style>
