<template>
  <b-card>
      <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-form-group>
            <label label-for="testTarget">{{ $t('Target Data') }}</label>
            <b-form-textarea
                id="textarea-auto-height"
                v-model="testTarget"
                class="source-input"
                placeholder="Tall textarea"
                rows="8"
                no-auto-shrink
              />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  computed: {
    testTarget: {
      get() {
        return this.$store.getters['sourceDataManagement/getTestSourceData'].testTarget
      },
      set(value) {
        this.$store.commit('sourceDataManagement/setTestSourceData', { key: 'testTarget', value })
      },
    },
  },
}
</script>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
