<template>
   <b-card>
    <b-row>
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <div class="mb-3" v-for="(item, index) in getSingleResult" :key="index">
          <table>
            <tr v-html="item.titleOutPut"></tr>
            <!-- eslint-disable -->
            <template v-for="itemDetay in item.outPuts">
              <tr v-if="item.sourceObject.addBarChart === 2">
                <td class="barchartmode2 nonselectable" :style="{backgroundImage: 'linear-gradient(90deg, #aaa ' + itemDetay.currentResult + '%, #444 ' + itemDetay.currentResult + '%'}"></td>
              </tr>
              <tr>
                <td class="singleleftcolumn">{{ itemDetay.currentResult.toFixed(1) }}</td>
                <td class="singlerightcolumn"> {{ itemDetay.resultsTable.replaceAll('*', ' ') }}</td>
              </tr>
              <tr v-if="item.sourceObject.addBarChart === 1">
                <td colspan= "2"  class="barchartmode1 nonselectable" :style="{backgroundImage: 'linear-gradient(90deg, #ff7f00 ' + itemDetay.currentResult + '%, #666 ' + itemDetay.currentResult + '%'}"></td>
              </tr>
            </template>
          </table>
        </div>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-row>
          <b-col md="6" xl="6">
            <div class="d-flex justify-content-between align-items-center my-1">
              <label for="addGradient">Add Gradient</label>
              <b-form-checkbox
                id="addGradient"
                :checked="true"
                switch
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar-send-invoice
              variant="primary"
              class="mb-75"
              block
              @click="runAllSingle()"
            >
              RUN ALL
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              v-for="item in getTargetButtons"
                :key="item.index"
              @click="dispatcher(item.index)"
            >
              {{ item.title }}
            </b-button>

          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  methods: {
    dispatcher(targetId) {
      this.$store.commit('sourceDataManagement/dispatchSingle', targetId)
    },

    runAllSingle() {
      this.$store.commit('sourceDataManagement/runAllSingle')
    },
  },
  computed: {
    getSingleResult() {
      return this.$store.getters['sourceDataManagement/getSingleResult']
    },
    getTargetButtons() {
      return this.$store.getters['sourceDataManagement/getTargetButtons']
    },
  },
}
</script>

<style scoped>
.nonselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.singleinfo {
  font-weight: normal;
}
.singleinfo::before {
  content: attr(data-nonselectable);
}   
.singleheader {
  text-align: left;
  width: 100%;
  padding-bottom: 0.7em;
}
.singleleftcolumn {
  text-align: right;
  padding-right: 0.7em
}
.singlerightcolumn {
  text-align: left;
  width: 100%;
}

.barchartmode2 + td {
  padding-left: 0.7em;
}
.barchartmode2 {
  min-width: 8em;
}

.barchartmode1 {
  width: 100%;
  height: 0.5em;
  box-shadow: inset 1px 1px 0px 0px #444;
}
</style>
