<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Source Data') }}
          </span>
      </template>

      <source-data-save />
    </b-tab>
    <!--/ general tab -->

    <!-- Test tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Target') }}
          </span>
      </template>

      <test-target />
    </b-tab>
    <!--/ Test tab -->

     <!-- Distance tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Distance') }}
          </span>
      </template>

      <test-distance />
    </b-tab>
    <!--/ Distance tab -->

    <!-- Single tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Single') }}
          </span>
      </template>

      <test-single />
    </b-tab>
    <!--/ Distance tab -->
  </b-tabs>
</template>

<script>
import SourceDataSave from './SourceDataSave.vue'
import TestTarget from './TestTarget.vue'
import TestDistance from './TestDistance.vue'
import TestSingle from './TestSingle.vue'

export default {
  components: {
    SourceDataSave,
    TestTarget,
    TestDistance,
    TestSingle,
  },
  methods: {
  },
}
</script>
