<template>
  <b-card>
      <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">

        <b-row>
          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('Source Title')" label-for="sourceTitle">
              <validation-provider #default="{ errors }" rules="required" name="First name">
                <b-form-input id="sourceTitle" v-model="sourceData.sourceTitle" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('Type')" label-for="sourceDataType">
              <validation-provider #default="{ errors }" rules="required" name="Type">
                <v-select
                  v-model="sourceData.sourceDataType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sourceTypeOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  input-id="sourceDataType"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('Source Description')" label-for="sourceDescription">
              <validation-provider #default="{ errors }" name="Desc">
                <b-form-input id="sourceDescription" v-model="sourceData.sourceDescription" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-form-group>
              <label label-for="source">{{ $t('Source Data') }}</label>
              <b-form-textarea
                  id="textarea-auto-height"
                  v-model="sourceData.source"
                  @change="onChangeSource()"
                  class="source-input"
                  placeholder="Tall textarea"
                  rows="8"
                  no-auto-shrink
                />
            </b-form-group>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="saveFetchSourceData.bind(this, true)"
              :onClickSave="saveFetchSourceData.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'

import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';

// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,
      max,
      sourceData: {
        sourceTitle: null,
        sourceDescription: null,
        source: null,
        sourceDataType: null,
      },

      sourceTypeOptions: StaticOptions.sourceTypeOptions,
    }
  },
  methods: {
    fetchSourceData() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('sourceDataManagement/fetchSourceData', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.sourceData = response.result;

              this.$store.commit('sourceDataManagement/setTestSourceData', { key: 'source', value: this.sourceData.source })
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },
    saveFetchSourceData(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.sourceData.source === null || this.sourceData.source === '') {
            AlertService.warning(this, 'Source data is required');
            return;
          }

          this.$store.dispatch('sourceDataManagement/saveSourceData', this.sourceData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })

                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.sourceData = response.result;
                  this.$router.push({ name: 'manage-source-data-save', params: { id: response.result.id } });
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    onChangeSource() {
      this.$store.commit('sourceDataManagement/setTestSourceData', { key: 'source', value: this.sourceData.source })
    },
  },
  created() {
    this.fetchSourceData();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
